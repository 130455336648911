.love {
    width: 100vw;
    color: burlywood;
    font-size: 2rem;
}

.marquee {
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}

.marquee p {
    display: inline-block;
    animation: marquee 15s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translate(-100%, 0);
    }

    100% {
        transform: translate(100%, 0);
    }
}